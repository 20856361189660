import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { ContentAdLargeRenderSlot } from '@wh/common/digital-advertising/components/ContentAdLargeRenderSlot/ContentAdLargeRenderSlot'
import { ContentAdMediumRenderSlot } from '@wh/common/digital-advertising/components/ContentAdMediumRenderSlot/ContentAdMediumRenderSlot'
import { ContentAdSmallRenderSlot } from '@wh/common/digital-advertising/components/ContentAdSmallRenderSlot/ContentAdSmallRenderSlot'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { ISbStoryData } from '@storyblok/react'
import { StoryblokAutoMotorStartpage, StoryblokRealEstateStartpage } from '@bbx/common/types/storyblok/StoryblokStartpage'
import { StoryblokTextWithPictureList } from '@wh/common/cms/components/bloks/StoryblokTextWithPictureList'

interface DirectSearchEntryWidgetProps {
    story: ISbStoryData<StoryblokRealEstateStartpage | StoryblokAutoMotorStartpage> | null
    taggingData: TaggingData
    taggingPrefix: string
}

export const StoryblokDirectSearchEntryWidget: FunctionComponent<DirectSearchEntryWidgetProps> = ({
    story,
    taggingData,
    taggingPrefix,
}) => (
    <Box
        display="flex"
        flexDirection={{ phone: 'column-reverse', tablet: 'row' }}
        justifyContent={{ tablet: 'space-between' }}
        marginBottom={{ phone: 'm', tablet: 'l' }}
    >
        <Box maxWidth={{ desktop: 622 }} flexGrow={1} testId="direct-search-wrapper">
            {story && (
                <StoryblokTextWithPictureList blok={story.content.TopWidget[0]} taggingData={taggingData} taggingPrefix={taggingPrefix} />
            )}
        </Box>
        <Box
            alignSelf={{ phone: 'center', tablet: 'flex-end' }}
            width={{ phone: '100%', tablet: 'auto' }}
            marginLeft={{ tablet: 'm' }}
            marginBottom={{ phone: 'm', tablet: 0 }}
        >
            <ContentAdLargeRenderSlot />
            <ContentAdMediumRenderSlot />
            <ContentAdSmallRenderSlot />
        </Box>
    </Box>
)
