import React, { FunctionComponent, ReactNode } from 'react'
import { Box } from '@wh-components/core/Box/Box'

// use for both SearchBox and SearchEntryAreaBottomIFrameContainer so they are aligned nicely without using css grid
export const SearchBoxRowContainer: FunctionComponent<{ left: ReactNode; right: ReactNode }> = (props) => (
    <Box width="100%" display="flex">
        <Box
            width={{ phone: '100%', tablet: '604px' }}
            marginRight={{ phone: '0', tablet: 'm' }}
            flex={{ phone: '1 1 auto', tablet: '0 0 auto' }}
        >
            {props.left}
        </Box>
        <Box display={{ phone: 'none', tablet: 'block' }} flex="1 1 100%">
            {props.right}
        </Box>
    </Box>
)
