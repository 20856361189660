import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { formatNumber } from '@wh/common/chapter/lib/formatter'

export const SEOHeading: FunctionComponent<{
    nrOfAdverts: number | undefined
    titleColor: string | undefined
    titleCounterColor: string | undefined
    title: string
}> = ({ nrOfAdverts, titleColor, titleCounterColor, title }) => (
    <Text>
        <Text color={titleColor ?? 'palette.primary.main'} fontSize="l" fontWeight="bold">
            {title}
        </Text>
        {nrOfAdverts && (
            <Text color={titleCounterColor ?? 'palette.verydarkgrey'} testId="ad-type-all-ads-counter">
                {` mit ${formatNumber(nrOfAdverts)} Anzeigen`}
            </Text>
        )}
    </Text>
)
