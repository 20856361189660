import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { LinkVomit } from '@bbx/search-journey/common/components/LinkVomit/LinkVomit'
import { LinkVomitPossibleValue, PossibleValueLinkVomit } from '../PossibleValueLinkVomit/PossibleValueLinkVomit'
import { LinkGroup } from '@wh/common/chapter/types/SeoMetaData'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

interface StartPageLinkVomitWidgetProps {
    heading: string
    taggingData?: TaggingData
    firstRow: LinkGroup[]
    secondRow: LinkGroup[]
}

export const StartPageLinkVomitWidget: FunctionComponent<StartPageLinkVomitWidgetProps> = ({
    heading,
    taggingData,
    firstRow,
    secondRow,
}) => (
    <Box borderTop={{ phone: 'owl', tablet: 0 }} marginTop={{ tablet: 'm' }} marginHorizontal={{ tablet: 'm' }} testId="link-vomit-wrapper">
        <Heading display={{ phone: 'none', tablet: 'block' }} level={2} fontSize="l" text={heading} marginBottom="s" />
        <LinkVomit linkGroups={firstRow} alignLinks={{ tablet: 'column' }} taggingData={taggingData} marginBottom={{ tablet: 'm' }} />
        <LinkVomit linkGroups={secondRow} alignLinks={{ tablet: 'column' }} />
    </Box>
)

interface LinkVomitWidgetProps {
    possibleValues: LinkVomitPossibleValue[]
}

export const LinkVomitWidget: FunctionComponent<LinkVomitWidgetProps> = ({ possibleValues }) => (
    <Box borderTop={{ phone: 'owl', tablet: 0 }} marginTop={{ tablet: 'm' }} marginHorizontal={{ tablet: 'm' }}>
        <PossibleValueLinkVomit title="Alle Marken" possibleValues={possibleValues} testId="make-vomit-all-makes" />
    </Box>
)
