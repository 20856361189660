import { AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { useEffect } from 'react'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { concatPathWithQueryParams, removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'

export function useStartPageTopAds(
    fetchTopAdsLink: ContextLink,
    setApiError: (value: ((prevState: boolean) => boolean) | boolean) => void,
    setTopAds: (
        value: ((prevState: AdvertSummaryList | undefined) => AdvertSummaryList | undefined) | AdvertSummaryList | undefined,
    ) => void,
    isInView: boolean,
) {
    useEffect(() => {
        let unmounted = false

        if (!fetchTopAdsLink.relativePath) {
            return
        }

        // the context-link contains "TOP_AD=topad_result", we need to change this here
        const adaptedRelativePath = concatPathWithQueryParams(
            removeUrlParameter(removeUrlParameter(fetchTopAdsLink.relativePath, 'rows'), 'TOP_AD'),
            {
                TOP_AD: 'topad_start',
                rows: '5',
            },
        )
        const adaptedFetchLink = { ...fetchTopAdsLink, relativePath: adaptedRelativePath }

        async function loadTopAds() {
            const result = await fetchFromContextLink<AdvertSummaryList>(adaptedFetchLink, undefined, undefined, {
                'Cache-Control': 'no-cache',
            }).catch(() => {
                setApiError(true)
                return undefined
            })

            if (unmounted) {
                return
            }

            setTopAds(result)
        }

        if (isInView) {
            loadTopAds()
        }

        return () => {
            unmounted = true
        }
    }, [fetchTopAdsLink, isInView, setApiError, setTopAds])
}
