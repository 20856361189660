import React, { FunctionComponent } from 'react'
import { SearchEntryAreaBottomIFrameType } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingPageModificationsState'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { Box } from '@wh-components/core/Box/Box'
import { css } from 'styled-components'
import { SearchBoxRowContainer } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/SearchEntryArea/SearchBoxRowContainer'

export const SearchEntryAreaBottomIFrameContainer: FunctionComponent<{
    config: NonNullable<SearchEntryAreaBottomIFrameType>
}> = (props) => {
    const shouldRenderSearchEntryAreaBottomRightIFrameBasedOnBreakpoint = useResponsiveValue(
        {
            phone: false,
            tablet: true,
        },
        false,
    )

    switch (props.config.type) {
        case 'one':
            return (
                <Box width="100%" height={props.config.height}>
                    <iframe
                        src={props.config.src}
                        frameBorder="0"
                        title="Werbung"
                        aria-hidden="true"
                        css={css`
                            display: block;
                            width: 100%;
                            height: 100%;

                            ${(p) => p.theme.media.print} {
                                display: none;
                            }
                        `}
                    />
                </Box>
            )
        case 'two': {
            const left = props.config.left
            const right = props.config.right
            return (
                <SearchBoxRowContainer
                    left={
                        left && (
                            <iframe
                                src={left.src}
                                frameBorder="0"
                                title="Werbung"
                                aria-hidden="true"
                                css={css`
                                    display: block;
                                    width: 100%;
                                    height: ${left.height}px;

                                    ${(p) => p.theme.media.print} {
                                        display: none;
                                    }
                                `}
                            />
                        )
                    }
                    right={
                        right &&
                        shouldRenderSearchEntryAreaBottomRightIFrameBasedOnBreakpoint && (
                            <iframe
                                src={right.src}
                                frameBorder="0"
                                title="Werbung"
                                aria-hidden="true"
                                css={css`
                                    display: block;
                                    width: 100%;
                                    height: ${right.height}px;

                                    ${(p) => p.theme.media.print} {
                                        display: none;
                                    }
                                `}
                            />
                        )
                    }
                />
            )
        }
    }
}
