import React, { FunctionComponent } from 'react'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { BapAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/BapAdvertSummaryComponent'
import { RealEstateAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/RealEstateAdvertSummaryComponent'
import { AutoMotorAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/AutoMotorAdvertSummaryComponent'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'

interface AdvertSummaryProps {
    advertSummary: AdvertSummary
    onClickTaggingEvent?: () => Promise<void> | void
}

export const AdvertSummaryDiscriminator: FunctionComponent<AdvertSummaryProps> = ({ advertSummary, onClickTaggingEvent }) => {
    const commonProps = {
        advertSummary: advertSummary,
        onClickTaggingEvent: onClickTaggingEvent,
    }

    if (advertSummary.verticalId === verticalIdMap.BAP) {
        return <BapAdvertSummaryComponent {...commonProps} />
    } else if (advertSummary.verticalId === verticalIdMap.ESTATE) {
        return <RealEstateAdvertSummaryComponent {...commonProps} />
    } else if (advertSummary.verticalId === verticalIdMap.MOTOR) {
        return <AutoMotorAdvertSummaryComponent {...commonProps} />
    }
    return null as unknown as JSX.Element
}
