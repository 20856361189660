import { AdvertSummary, AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { AdvertSummarySkeleton } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import { Box } from '@wh-components/core/Box/Box'
import { TestProps } from '@wh-components/core/common'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import { useStartPageTopAds } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/ContentArea/useStartPageTopAds'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { HorizontalSlider } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { css } from 'styled-components'
import { Heading } from '@wh-components/core/Heading/Heading'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { AdvertSummaryDiscriminator } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummaryDiscriminator'
import { ContextLink } from '@bbx/common/types/contextLinks'

type StartPageTopAdsWidgetProps = {
    fetchTopAdsLink: ContextLink
    title: string
    taggingData: TaggingData
    taggingEvent: TaggingActionEvent
}

export const StartPageTopAdsWidget: FunctionComponent<StartPageTopAdsWidgetProps> = ({
    fetchTopAdsLink,
    title,
    taggingData,
    taggingEvent,
}) => {
    const [topAds, setTopAds] = useState<AdvertSummaryList | undefined>(undefined)
    const [apiError, setApiError] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const [isInView] = useIsInView(ref, '100px')

    useStartPageTopAds(fetchTopAdsLink, setApiError, setTopAds, isInView)

    return !apiError ? (
        <HorizontalSlider
            heading={<Heading level={2} fontSize="l" ref={ref} text={title} />}
            testIdPrefix="top-ads"
            marginBottom="l"
            css={css`
                margin-right: -${(p) => p.theme.space.m}px;

                ${(p) => p.theme.media.desktop} {
                    margin-right: 0;
                }
            `}
        >
            {topAds
                ? topAds.advertSummary.map((summary, index) => (
                      <StartPageTopAd
                          key={summary.id}
                          topAd={summary}
                          taggingData={taggingData}
                          taggingEvent={taggingEvent}
                          testId={index === 0 ? 'first-top-ad' : ''}
                      />
                  ))
                : Array.from({ length: 5 }).map((_, index) => <AdvertSummarySkeleton key={index} />)}
        </HorizontalSlider>
    ) : null
}

const StartPageTopAd: FunctionComponent<
    { topAd: AdvertSummary; taggingData: TaggingData; taggingEvent: TaggingActionEvent } & TestProps
> = ({ topAd, taggingData, testId, taggingEvent }) => {
    const topAdVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const topAdVisibilityCallback = useCallback(() => {
        const adUuid = getAttributeValue(topAd.attributes.attribute, 'AD_UUID')
        const orgUuid = getAttributeValue(topAd.attributes.attribute, 'ORG_UUID')

        logBdsEvent(topAd.id, 'top-atz-start-page-viewed')
        callActionEvent('vertical_home_topad_view', taggingData, {
            ad_id: topAd.id,
            ad_uuid: adUuid,
            org_uuid: orgUuid,
        })
    }, [topAd, taggingData])
    useIntersectionObserver(topAdVisibilityTrackingRef, { triggerOnce: true, threshold: 0.5 }, [topAd], topAdVisibilityCallback)

    const advertSummary = useCallback(() => {
        const adUuid = getAttributeValue(topAd.attributes.attribute, 'AD_UUID')
        const orgUuid = getAttributeValue(topAd.attributes.attribute, 'ORG_UUID')

        return (
            <AdvertSummaryDiscriminator
                advertSummary={topAd}
                onClickTaggingEvent={() => {
                    logBdsEvent(topAd.id, 'top-atz-start-page-clicked')
                    return callActionEvent(taggingEvent, taggingData, {
                        ad_id: topAd.id,
                        ad_uuid: adUuid,
                        org_uuid: orgUuid,
                    })
                }}
            />
        )
    }, [taggingData, topAd, taggingEvent])

    return (
        <Box testId={testId} ref={topAdVisibilityTrackingRef}>
            {advertSummary()}
        </Box>
    )
}
